import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Layout from './pages/Layout';
import LandingPage from './pages/landingPage'
import AnalysisPage from './pages/analysisPage';
import ErrorPage from './pages/errorPage';

function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<LandingPage />} />
          <Route path='/view/:id' element={<AnalysisPage />} />
          <Route path='*' element={<ErrorPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
