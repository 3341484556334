import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { SERVER_API } from '../../constants'

const getAnalysisReport = createAsyncThunk('analysis/report', async (reportId) => {
  return axios.get(`${SERVER_API.BASE_URL}/view-report/${reportId}`)
    .then(response => {
      return { data: response.data };
    })
    .catch(error => {
      if (error.response.data) {
        return { error: error.response.data };
      } else {
        return { error };
      }
    });
});

export { getAnalysisReport };
