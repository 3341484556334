function HookDetailsTable({ details, interval }) {
  const renderedRows = details["frames"].map((item, index) => {
    return (
      <tr key={item["frame_number"]}>
        <td>{item["frame_number"]}</td>
        <td>{item["visual_description"]}</td>
        <td><img src={item["image_url"]} alt={item["visual_description"]} /></td>
        {index === 0 && <td rowSpan={details["frames"].length}><p>{details["audio_transcript"][interval + "_sec"]}</p></td>}
      </tr>
    );
  });

  return (
    <div className="list">
      <table>
        <thead>
          <tr>
            <th>Time/frame</th>
            <th>Frame Description</th>
            <th>Storyboard image</th>
            <th>Audio at {interval} sec</th>
          </tr>
        </thead>
        <tbody>
          {renderedRows}
        </tbody>
      </table>
    </div>
  );
}

export default HookDetailsTable;
