import { useState, useEffect, useCallback } from 'react';
import { createGIF } from 'gifshot';
import VideoDetailsCheckbox from './VideoDetailsCheckbox';
import MultilineText from '../../components/MultilineText';
import HookDetailsTable from './HookDetailsTable';

function HookIdeaSection({ ad, title }) {
  const [screenshotGif, setScreenshotGif] = useState(null);

  const loadImages = useCallback((imageUrls) => {
    if (imageUrls) {
      let images = [];
      let loadCount = 0;

      imageUrls.forEach(url => {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = () => {
          images.push({
            url: url,
            width: img.width,
            height: img.height
          });
          loadCount++;

          if (loadCount === imageUrls.length) {
            generateGif(images);
          }
        };
        img.src = url;
      });
    }
  }, []);

  const generateGif = (images) => {
    // Calculate dimensions for the GIF
    // Example: Using the dimensions of the first image
    const gifWidth = images[0].width;
    const gifHeight = images[0].height;

    createGIF({
      'images': images.map(image => image.url),
      'gifWidth': gifWidth,
      'gifHeight': gifHeight,
      frameDuration: 5,
      sampleInterval: 10,
    }, function (obj) {
      if (!obj.error) {
        setScreenshotGif(obj.image);
      }
    });
  };

  useEffect(() => {
    if (ad["screenshot_series"]) {
      loadImages(ad["screenshot_series"]);
    }
  }, [loadImages, ad]);

  return (
    <div className="mt-10 w-full">
      <p className="text-lg font-bold underline">{title}</p>
      {ad["video_info_item"] && <VideoDetailsCheckbox adVideo={ad["video_info_item"]} />}
      <p className="font-bold underline">Hook (0-10 sec)</p>
      {screenshotGif && <img className="mt-8 mb-4 max-h-80 object-contain object-left" src={screenshotGif} alt="the first 10 seconds of the video" />}
      {ad["descriptions"] && <>
        <p className="mt-12 font-bold">Description of visuals:</p>
        <p className="my-4 underline">0~5 sec</p>
        <MultilineText>{ad["descriptions"][0]}</MultilineText>
        <p className="my-4 underline">6~10 sec</p>
        <MultilineText>{ad["descriptions"][1]}</MultilineText>
      </>}
      {/*{ad["video_analysis_json"] && <>*/}
      {/*  <p className="mt-8 mb-4 font-bold">Analysis JSON:</p>*/}
      {/*  <MultilineText>{ad["video_analysis_json"]}</MultilineText>*/}
      {/*</>}*/}
      {ad["new_hook"] && <>
        <p className="mt-8 mb-4 font-bold">New Hook:</p>
        <p className="font-bold mt-4">0~5 sec</p>
        {ad["new_hook"]["video_analysis"]["0-5_sec"] && <HookDetailsTable details={ad["new_hook"]["video_analysis"]["0-5_sec"]} interval={"0-5"} />}
        <p className="font-bold mt-8">6~10 sec</p>
        {ad["new_hook"]["video_analysis"]["6-10_sec"] && <HookDetailsTable details={ad["new_hook"]["video_analysis"]["6-10_sec"]} interval={"6-10"} />}
      </>}
    </div>
  );
}

export default HookIdeaSection;
