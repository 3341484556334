import { forwardRef } from 'react';
import classNames from 'classnames';

const InputBox = forwardRef(function InputBox({ className, ...rest }, ref) {
  const classes = classNames(`border-8 border-[#305BE8] rounded-2xl p-2 sm:p-3 placeholder:text-black bg-transparent`, className);

  return (
    <input ref={ref} className={classes} {...rest} />
  );
});

export default InputBox;
