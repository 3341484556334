import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { landingNextStep, submitEmail, getTopSimilarAdVideos, buildReport } from '../../store';
import useThunk from '../../hooks/use-thunks';
import VidtaoButton from '../../components/VidtaoButton';
import { LANDING_STEP } from '../../constants'
import Heading1 from '../../components/Heading1';
import LandingBox from './landingBox';
import InputBox from '../../components/InputBox';
import VideoDetailsCheckbox from "../analysisPage/VideoDetailsCheckbox";

function LandingPage() {
  const dispatch = useDispatch();
  const [doSubmitEmail] = useThunk(submitEmail);
  const [doGetTopSimilarAdVideos] = useThunk(getTopSimilarAdVideos);
  const [doBuildReport] = useThunk(buildReport);
  const landing = useSelector(state => state.landing);
  const [email, setEmail] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [selectedVideoId, setSelectedVideoId] = useState('');
  const inputRef = useRef(null);

  const handleIntroSubmit = event => {
    event.preventDefault();
    dispatch(landingNextStep());
  };

  const handleEmailSubmit = event => {
    event.preventDefault();
    doSubmitEmail(email);
  };

  const handleUrlSubmit = event => {
    event.preventDefault();
    if (videoUrl.trim()) {
      doGetTopSimilarAdVideos({ email, videoUrl });
    }
  };

  const handleBuildReport = event => {
    event.preventDefault();
    if (selectedVideoId) {
      doBuildReport({ email, videoUrl, videoId: selectedVideoId })
    }
  };

  let content = ""

  if (landing.currentStep === LANDING_STEP.INTRO) {
    content = (
      <>
        <Heading1 className="mt-2 mb-4 sm:mb-8 md:w-[32rem]">⚓ YouTube Ad Hook Generator</Heading1>
        <p className="text-lg md:text-2xl mb-6">See how you stack up against top ads in your market & discover your YouTube ad’s…</p>
        <div className="text-xl max-w-3xl">
          <p className="mb-2">✅ <span className="font-bold underline">Skip Shield Score</span>: How well does your ad grab attention in the first 5 seconds?</p>
          <p className="mb-2">⚓ <span className="font-bold underline">Attention Anchor Rating</span>: How well does your ad do in seconds 5-10?</p>
          <p className="mb-2">🎯 <span className="font-bold underline">Persuasion Score</span>: Your ad’s overall effectiveness</p>
          <p>It’s <span className="underline">100% Free</span> & we’ll give you a <span className="font-bold">detailed, custom 19 page analysis</span> packed with actionable insights you can use to improve your ad’s performance, right away.</p>
        </div>
        <form className="my-10" onSubmit={handleIntroSubmit}>
          <VidtaoButton>Generate My Hooks</VidtaoButton>
        </form>
      </>
    );
  } else if (landing.currentStep === LANDING_STEP.EMAIL_INPUT) {
    content = (
      <LandingBox step={1}>
        <Heading1 className="mt-2 mb-4">Where Can We Send Your YouTube Ad Hooks?</Heading1>
        <p className="text-lg md:text-2xl mb-6">We’re building you 3 <span className="underline">new, custom YouTube ad hooks</span> to test. Where can we send this info? 👇</p>
        <form onSubmit={handleEmailSubmit}>
          <InputBox id="email" type="email" className="w-full text-sm md:text-md lg:text-xl" placeholder="Enter your best email here…" value={email} onChange={event => setEmail(event.target.value)} required />
          <VidtaoButton className="mx-auto my-10" disabled={landing.isWaiting}>Next Step...</VidtaoButton>
        </form>
        {landing.errorMessage && <p className="mb-4 text-red-500 text-left">{landing.errorMessage}</p>}
      </LandingBox>
    );
  } else if (landing.currentStep === LANDING_STEP.URL_INPUT) {
    content = (
      <LandingBox step={2}>
        <Heading1 className="mt-2 mb-4">⚓ Enter Your YouTube Ad URL</Heading1>
        <p className="text-lg md:text-2xl mb-6">Our AI will generate <span className="font-bold">3 new YouTube ad hook ideas</span> based on what's working right now across ALL direct response markets...</p>
        <form onSubmit={handleUrlSubmit}>
          <div className="relative w-full mb-8">
            <InputBox id="video-url" ref={inputRef} className="w-full text-sm md:text-md lg:text-xl text-center" value={videoUrl} onChange={event => setVideoUrl(event.target.value)} required />
            {videoUrl.length > 0 ||
            <div className="absolute inset-2 cursor-text flex justify-center items-center" onClick={() => inputRef.current.focus()}>
              <p className="text-xs sm:text-base lg:text-xl text-center">Enter your <span className="font-bold">YouTube ad video url here</span> & click “Generate Hooks” to begin</p>
            </div>}
          </div>
          <VidtaoButton className="mx-auto my-10" disabled={landing.isWaiting}>Generate Hooks</VidtaoButton>
        </form>
        {landing.errorMessage && <p className="mb-4 text-red-500 text-left">{landing.errorMessage}</p>}
      </LandingBox>
    );
  } else if (landing.currentStep === LANDING_STEP.SELECT_COMPARISON_AD) {
    content = (
      <LandingBox step={3}>
        <Heading1 className="mt-2 mb-4">Select a Top-Performing Competitor Ad</Heading1>
        <div className="text-left">
          <p className="text-lg md:text-2xl mb-6"><span className="font-bold">How does your ad compare to other high-performing ads in your market?</span> Below are 5 proven, high-performing YouTube ads <span className="underline">that are most similar to your ad</span>.</p>
          <p className="text-lg md:text-2xl mb-6 font-bold">Which one do you want to compare your ad with?</p>
          <p className="text-lg md:text-2xl mb-6 font-bold">Select one and click “Get My Score” and we’ll start generating your report & analysis! 👇</p>
          {landing.similarAdVideos && <>
            {landing.similarAdVideos[0].map((adVideo, index) => {
              return (<div key={index}>
                <VideoDetailsCheckbox adVideo={adVideo} name="video-id" onChange={event => setSelectedVideoId(event.target.value)} />
              </div>);
            })}

            {landing.similarAdVideos[1].length > 0 && <>
              <p className="text-lg my-5">Other ads from this channel</p>
              {landing.similarAdVideos[1].map((adVideo, index) => {
                return (<div key={index}>
                  <VideoDetailsCheckbox adVideo={adVideo} name="video-id" onChange={event => setSelectedVideoId(event.target.value)} />
                </div>);
              })}
            </>}
          </>}
          <form className="mt-6 mb-10" onSubmit={handleBuildReport}>
            <VidtaoButton className="mx-auto" disabled={landing.isWaiting || !selectedVideoId}>Get My Score</VidtaoButton>
          </form>
        </div>
        {landing.errorMessage && <p className="mb-4 text-red-500 text-left">{landing.errorMessage}</p>}
      </LandingBox>
    );
  } else if (landing.currentStep === LANDING_STEP.BUILDING_REPORT) {
    content = (
      <>
        <Heading1 className="mt-2 mb-4">Thanks! Give us 5-10 minutes for our AI to create your 3 new YouTube ad hooks</Heading1>
        <div className="mt-6 max-w-3xl">
          <p className="text-lg md:text-2xl mb-6">In the meantime, want to check out our library of <span className="underline">over 7 million unlisted YouTube ads? (and landing pages)</span></p>
          <p className="text-lg md:text-2xl">Click below to check out VidTao for free:</p>
        </div>
        <a href="https://app.vidtao.com/?source=creative-analysis-app-post-optin" target="_blank" rel="noreferrer" className="my-10"><VidtaoButton>Try VidTao</VidtaoButton></a>
      </>
    );
  } else if (landing.currentStep === LANDING_STEP.REPORT_AVAILABLE) {
    content = (
      <>
        <Heading1 className="mt-2 mb-4">Your report has already been built. Please check your email.</Heading1>
      </>
    );
  }

  return (
    <>{content}</>
  );
}

export default LandingPage;
