import { Outlet } from 'react-router-dom';
import vidtao from '../images/vidtao.png';

const Layout = () => {
  return (
    <div className="max-w-5xl mx-auto px-2">
      <img className="w-64 sm:96 mx-2 my-4" src={vidtao} alt="VidTao logo" />
      <div className="flex flex-col items-center mx-auto max-w-4xl w-full">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
