import classNames from 'classnames';

function ExternalLink({ children, className, ...rest }) {
  const classes = classNames("underline text-[#15c]", className)

  return (
    <a className={classes} target="_blank" rel="noreferrer" {...rest}>{children}</a>
  );
}

export default ExternalLink;
